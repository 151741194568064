import React, { useState } from 'react';
import { 
  Dialog, DialogTitle, DialogContent, Button, Box, TextField, 
  DialogActions, FormControl, FormLabel, RadioGroup, FormControlLabel, 
  Radio, CircularProgress, Tooltip 
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { signup, reset_password } from '../../actions/auth';

const FormDialog = ({ open, handleClose, onActionFeedback, user }) => {
  const dispatch = useDispatch();

  const onSubmit = async (values, { setSubmitting, setErrors }) => {
    try {
      const signupData = {
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        group_name: values.role,
        phone_number: values.lastFourPhoneNumber, // Added new field
      };

      // Try creating the user
      const signupResponse = await dispatch(signup(signupData));

      if (signupResponse) {
        // Reset password after successful signup
        await dispatch(reset_password(values.email));
        onActionFeedback(true, "Account created successfully! Check your email for password reset.");
        handleClose(); // Close the dialog after submission
      } else {
        onActionFeedback(false, "Failed to set the password");
      }
    } catch (error) {
      console.error('Error occurred:', error);

      // Check if the error has a response
      if (error.response) {
        const status = error.response.status;

        // Handle errors based on status code
        switch (status) {
          case 400:
            onActionFeedback(false, "An account with this email already exists. Please use a different email.");
            break;
          case 403:
            onActionFeedback(false, "You do not have permission to perform this action.");
            break;
          case 404:
            onActionFeedback(false, "The requested resource was not found.");
            break;
          case 500:
            onActionFeedback(false, "A server error occurred. Please try again later.");
            break;
          default:
            onActionFeedback(false, `An error occurred (Status: ${status}). Please try again.`);
            break;
        }
      } else {
        // No response from the server (network error, timeout, etc.)
        onActionFeedback(false, "Network error. Please check your connection and try again.");
      }
    } finally {
      setSubmitting(false); // Stop submission state
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>Create New User</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            lastFourPhoneNumber: "", // New field
            role: "student",
          }}
          validationSchema={yup.object().shape({
            firstName: yup.string().required("Required"),
            lastName: yup.string().required("Required"),
            email: yup.string().email("Invalid email").required("Required"),
            lastFourPhoneNumber: yup
              .string()
              .matches(/^\d{4}$/, "Must be exactly 4 digits")
              .required("Required"),
            role: yup.string().required("Required"),
          })}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box display="grid" gap="20px" gridTemplateColumns="repeat(4, minmax(0, 1fr))">
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="First Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.firstName}
                  name="firstName"
                  error={touched.firstName && Boolean(errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Last Name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastName}
                  name="lastName"
                  error={touched.lastName && Boolean(errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="email"
                  label="Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  name="email"
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{ gridColumn: "span 4" }}
                />
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Last 4 Digits of Phone Number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.lastFourPhoneNumber}
                  name="lastFourPhoneNumber"
                  error={touched.lastFourPhoneNumber && Boolean(errors.lastFourPhoneNumber)}
                  helperText={touched.lastFourPhoneNumber && errors.lastFourPhoneNumber}
                  inputProps={{ maxLength: 4, pattern: "[0-9]*" }} // Ensure numeric input
                  sx={{ gridColumn: "span 4" }}
                />
                <FormControl fullWidth sx={{ gridColumn: "span 4" }}>
                  <FormLabel>Role</FormLabel>
                  <RadioGroup
                    row
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                  >
                    <FormControlLabel value="student" control={<Radio />} label="Student" />
                    <FormControlLabel value="teacher" control={<Radio />} label="Teacher" />
                    {user === 'admin' && <FormControlLabel value="staff" control={<Radio />} label="Staff" />}
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box display="flex" justifyContent="end" mt="20px">
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                    {isSubmitting ? <CircularProgress size={24} /> : "Create New User"}
                  </Button>
                </DialogActions>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default FormDialog;
