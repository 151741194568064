import React, { useEffect, useState } from 'react';
import {
  Typography,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  Box,
  MenuItem,
  IconButton,Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import { editImgPercentage } from '../actions/auth';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


const StyledDialogTitle = styled(DialogTitle)({
  backgroundColor: 'black',
  color: 'white'
});
const StyledDialogActions = styled(DialogActions)({
  backgroundColor: '#e0e0e0',
  padding: '10px 24px'
});
const StyledDialogContent = styled(DialogContent)({
  marginTop: '8px',
  paddingTop: '20px',
  paddingBottom: '20px',
  backgroundColor: '#f5f5f5'
});

const EditQuestionDialog = ({ isOpen, onClose, question, onSave,user }) => {
  const [formState, setFormState] = useState({
    text: '',
    description: '',
    choices: ['', '', '', ''],
    correct_choice: 0,
    correct_numerical_answer: 0,
    questionType: 'MC',
  });
  const [textEditorState, setTextEditorState] = useState('');
const [descriptionEditorState, setDescriptionEditorState] = useState('');
const [imageScale, setImageScale] = useState(user?.img_percentage || 100);
const [isEditingScale, setIsEditingScale] = useState(false);

  const choiceLetters = ['A', 'B', 'C', 'D']; // Extend this if you have more than 4 choices
  const parseFraction = (input) => {
    if (typeof input !== 'string') {
      input = String(input); // Convert to string if it's a number or other type
    }
  
    if (input.includes('/')) {
      const parts = input.split('/');
      if (parts.length === 2) {
        const numerator = parseFloat(parts[0]);
        const denominator = parseFloat(parts[1]);
        if (!isNaN(numerator) && !isNaN(denominator) && denominator !== 0) {
          return numerator / denominator;
        }
      }
      return input; // Return original input if parsing fails
    }
  
    const parsed = parseFloat(input);
    return isNaN(parsed) ? input : parsed;
  };
  
  useEffect(() => {
    if (isOpen && question) {
      setFormState({
        choices: question.choices || ['', '', '', ''],
        correct_choice: question.correct_choice || 0,
        correct_numerical_answer: question.correct_numerical_answer || 0,
        questionType: question.question_type || 'MC',
      });
      setTextEditorState(question.text || '');
    setDescriptionEditorState(question.description || '');
    // console.log('form state',formState)
    }
  }, [isOpen, question]);
  const handleChange = (prop) => (event) => {
    const isReactQuillChange = event && event.hasOwnProperty('getEditor'); // or another ReactQuill-specific property check
    if (isReactQuillChange) {
      setFormState({ ...formState, [prop]: event });
    } else if (event && event.target) {
      setFormState({ ...formState, [prop]: event.target.value });
    }
  };
  const handleEditScaleClick = () => {
    setIsEditingScale(true);
  };

  
  const handleChoiceChange = (index) => (content) => {
    setFormState(prevState => {
      const updatedChoices = [...prevState.choices];
      updatedChoices[index] = content;
      return { ...prevState, choices: updatedChoices };
    });
  };
  
  const handleSaveScaleClick = async () => {
    try {
      const response = await editImgPercentage(user.id,imageScale);

    } catch (error) {
    }
    setIsEditingScale(false);

  };
  const handleSave = () => {
    const parsedAnswer = parseFraction(formState.correct_numerical_answer);
    const updatedFormState = {
        ...formState,
        text: textEditorState,
        description: descriptionEditorState,
        correct_numerical_answer: parsedAnswer,
    };
  
    onSave({
      id: question.id,
      ...updatedFormState,
    });
  };
  


  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <StyledDialogTitle>Edit Question</StyledDialogTitle>
      
      <StyledDialogContent>
      <Box display="flex" alignItems="center" mb={2}>
  <Typography variant="body1" component="div" display="flex" alignItems="center">
    Image Scale: 
    {isEditingScale ? (
      <>
        <TextField
          value={imageScale}
          onChange={(e) => setImageScale(e.target.value)}
          type="number"
          inputProps={{ min: 1, max: 100 }}
          style={{ width: '70px', marginLeft: '8px', marginRight: '8px' }}
          size="small"
        />
        <span style={{ marginRight: '8px' }}>%</span>
        <Button color="primary" onClick={handleSaveScaleClick} size="small" variant="contained" style={{ marginRight: '8px' }}>
          Save
        </Button>
        <Button color="secondary" onClick={() => setIsEditingScale(false)} size="small" variant="contained">
          Cancel
        </Button>
      </>
    ) : (
      <span style={{ marginLeft: '8px', marginRight: '8px' }}>{`${imageScale}%`}</span>
    )}
    {!isEditingScale && (
      <IconButton color="primary" onClick={handleEditScaleClick} size="small">
        <EditOutlinedIcon />
      </IconButton>
    )}
    <Tooltip title="This sets the scale of images inserted in the question text, description, and choices, because everyone's laptop's screen size and resolution is different">
      <IconButton size="small">
        <InfoOutlinedIcon />
      </IconButton>
    </Tooltip>
  </Typography>
</Box>
     <strong>Question:</strong>
      <ReactQuill value={textEditorState} onChange={setTextEditorState} placeholder="Enter the question text..." theme="snow"     style={{ minHeight: '10vh', maxHeight: '45vh', overflowY: 'auto' }}
 />
      <strong>Description:</strong>
      <ReactQuill value={descriptionEditorState} onChange={setDescriptionEditorState} placeholder="Enter the question description (optional)..." theme="snow"     style={{ minHeight: '10vh', maxHeight: '40vh', overflowY: 'auto' }}
 />
    {formState.questionType === 'MC' && (
  <>
    <Box>
      {formState.choices.map((choice, index) => (
        <>
        
        
        <Box key={`choice-${index}`} mb={5}>
  <Typography variant="h6" gutterBottom>Choice {String.fromCharCode(65 + index)}</Typography>
  <ReactQuill
    value={choice}
    onChange={(content, delta, source, editor) => handleChoiceChange(index)(content)}
    theme="snow"
    style={{ minHeight: '10vh', maxHeight: '40vh', overflowY: 'auto' }}
  />
</Box>


      </>
      ))}
        <FormControl fullWidth margin="normal">
  <InputLabel>Correct Choice</InputLabel>
  <Select
    value={formState.correct_choice}
    label="Correct Choice"
    onChange={handleChange('correct_choice')}
  >
    {formState.choices.map((_, index) => (
      <MenuItem key={index} value={index}>{`Choice ${choiceLetters[index]}`}</MenuItem>
    ))}
  </Select>
</FormControl>


    </Box>
    
  </>
)}
        {formState.questionType === 'NI' && (
  <TextField
    label="Correct Numerical Answer"
    variant="outlined"
    fullWidth
    value={formState.correct_numerical_answer}
    onChange={handleChange('correct_numerical_answer')}
    margin="normal"
  />
)}

      </StyledDialogContent>
      <StyledDialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleSave} color="primary" variant="contained">Save</Button>
      </StyledDialogActions>
    </Dialog>
  );
};

export default EditQuestionDialog;
