import React, { useState, useEffect, useCallback,useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Toolbar, Typography, Breadcrumbs,Link as MuiLink,Button,Tabs,Tab, Grid, Box,styled,Popover, FormControl } from '@mui/material';
import { useSelector } from 'react-redux';

import MultipleChoiceQuestion from './MultipleChoice';
import {fetchExamForPreview } from '../../actions/auth';
// import FocusLock from 'react-focus-lock';
import './testpages.css'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
// import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import DOMPurify from 'dompurify';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import Footer from './Footer';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
// import CreateQuestionDialog from '../../components/CreateQuestionDialog';
import CalculatorComponent from '../../components/CalculatorComponent';

import LoadingOverlay from '../../components/LoadingOverlay';


const Testpagepreview = () => {
  const { examId } = useParams(); // Get examId from URL parameters
  // console.log(examId,'examId');
    const { studentexamId } = 3;
    const myCalculatorRef = useRef(null)

    const user = useSelector(state => state.auth.user);


  const [showReviewPage, setShowReviewPage] = useState(false);

    const navigate = useNavigate();
    const [currentExam, setCurrentExam] = useState({ modules: [] }); // Initialize with an empty modules array
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [calculatorVisible, setCalculatorVisible] = useState(false);
    const [calculatorPosition, setCalculatorPosition] = useState({ x: 0, y: 0 });
    const [referencePosition, setReferencePosition] = useState({ x: 0, y: 0 });
    const [isMinimapVisible, setIsMinimapVisible] = useState(false);
    const [markedForReview, setMarkedForReview] = useState({});

    const minimapRef = useRef(null); // Add this line

    const [isCalculatorExpanded, setIsCalculatorExpanded] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);


    const [anchorEl, setAnchorEl] = useState(null);
    const [userInput, setUserInput] = useState('');
    const [inputError, setInputError] = useState('');

    const [referenceVisible, setReferenceVisible] = useState(false);
    
    const [crossedOut, setCrossedOut] = useState({});

    const [crossedIcon, setCrossedIcon] = useState(false);




    
 
const [currentModuleIndex, setCurrentModuleIndex] = useState(0);
// console.log('currentModuleIndex',currentModuleIndex)
const currentModule = currentExam?.modules[currentModuleIndex];

const toggleMarkForReview = (questionId) => {
  setMarkedForReview(prev => ({
    ...prev,
    [questionId]: !prev[questionId]
  }));
};
const processHtmlContent = (html) => {
  return html.replace(/<p>/gi, '<span>').replace(/<\/p>/gi, '</span>');
};
const toggleCalculator = () => {
  setCalculatorVisible(!calculatorVisible);
}
const toggleReference = () => {
  setReferenceVisible(!referenceVisible);
}  
const setMiniMapFalse = () => {
  setIsMinimapVisible(false);
};
const toggleCrossedOut = (questionId, choiceIndex) => {
  // console.log('choice index', choiceIndex, 'questionId', questionId);
  setCrossedOut(prev => {
    // Ensure the questionId entry exists and is an array, otherwise start with an array of falses.
    const currentChoices = prev[questionId] || Array.from({ length: 4}, () => false);
    
    return {
      ...prev,
      [questionId]: currentChoices.map((item, index) => 
        index === choiceIndex ? !item : item
      )
    };
  });
};
const toggleTrueCrossedOut = (questionId, choiceIndex) => {
  setCrossedOut(prev => {
    // Check if there is already an entry for this question; if not, initialize with an array of false values.
    const currentChoices = prev[questionId] || Array.from({ length: 4 }, () => false);
// console.log(currentChoices);
    // Return the new state with the specified choiceIndex set to true, while other choices retain their current state.
    return {
      ...prev,
      [questionId]: currentChoices.map((item, index) => 
        index === choiceIndex ? false : item  // Set the element at choiceIndex to true, others are unchanged.
      )
    };
  });
};

const toggleMinimap = () => {
  setIsMinimapVisible(prevVisibility => !prevVisibility);
};
const handleChange = (event, newValue) => {
  // console.log('event',newValue)
  setCurrentModuleIndex(newValue);
  setCurrentQuestionIndex(0); // Reset question index when module changes
};
const hasQuestionsInCurrentModule = useCallback(() => {
  // console.log('current module',currentModule)
  return currentModule && currentModule.questions && currentModule.questions.length > 0;
}, [currentModule]);


const openReviewPage = () => {
  const currentQuestionId = currentExam?.current_module?.questions[currentQuestionIndex]?.id;
  const currentAnswer = selectedAnswers[currentQuestionId];
  // saveCurrentAnswer(currentQuestionId, currentAnswer); // Directly save the current answer
  setShowReviewPage(true);
};

const isMarkedReview = useCallback((questionId) => {
  // console.log('isMarked for review',questionId,markedForReview[questionId] !== undefined)
  // console.log(markedForReview)
  return markedForReview[questionId] === true;
}, [markedForReview]);



    const open = Boolean(anchorEl); // This line is used to control the display of the popover based on whether we have an anchor element


   


const [isCalculatorLoaded, setIsCalculatorLoaded] = useState(false);

const toggleCalculatorVisibility = useCallback(() => {
    setCalculatorVisible(!calculatorVisible);
    if (!isCalculatorLoaded) {
        setIsCalculatorLoaded(true); 
    }
}, [calculatorVisible, isCalculatorLoaded]);

const setCorrectAnswers = useCallback((exam) => {
  const newSelectedAnswers = {};
  exam.modules.forEach(module => {
      module.questions.forEach(question => {
          newSelectedAnswers[question.id] = question.question_type === 'NI' 
              ? question.correct_numerical_answer 
              : question.correct_choice;
      });
  });
  setSelectedAnswers(newSelectedAnswers);
}, []);
const fetchExamStatus = useCallback(async () => {
  setLoading(true);
  try {
    const exam = await fetchExamForPreview(examId, navigate);
    // console.log(exam)
    if (exam) {
        setCurrentExam(exam);
        setCorrectAnswers(exam);
        console.log('exam',exam)
    } else {
        setError('Exam data not found');
    }
} catch (err) {
    console.error('Error fetching exam status:', err);
    setError('Failed to load exam data');
} finally {
    setLoading(false);
}
}, [examId, navigate, setCorrectAnswers]);





useEffect(() => {

      // Existing logic for students taking the exam
      fetchExamStatus();
  
}, [examId, fetchExamForPreview, fetchExamStatus]);










  const handleNextQuestion = () => {
    if (currentQuestionIndex < currentModule?.questions.length - 1) {

      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      // openReviewPage();
    }
  };
    
  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
};
    




  const handleSelectQuestion = (index) => {
    setCurrentQuestionIndex(index);
    const newQuestionId = currentModule.questions[index].id;
    const newAnswer = selectedAnswers[newQuestionId] || '';
    setUserInput(newAnswer); // Update userInput to reflect the selected question's answer
};


  
  const isQuestionAnswered = useCallback((questionId) => {
    return selectedAnswers[questionId] !== undefined;
}, [selectedAnswers]);

const handleAnswerSelect = (questionId, event, isMCQuestion) => {
  const answer = isMCQuestion ? event.target.value : userInput;
  setSelectedAnswers((prev) => ({ ...prev, [questionId]: answer }));
  if (!isMCQuestion) {
    setUserInput(answer); // Update userInput for numerical or textual answers
  }
  // debouncedDispatch(questionId, answer);

};

const renderInputError = () => {
  if (inputError) {
      return (
          <Typography color="error" style={{ marginTop: '10px' }}>
              {inputError}
          </Typography>
      );
  }
  return null;
};
if (loading) {
  return (

<LoadingOverlay/>
  );
}
if (error) return <Typography color="error">{error}</Typography>;
if (!currentExam) return <Typography>Exam is loading...</Typography>;
if (!hasQuestionsInCurrentModule()) {
  return <Typography>No questions available for this module.</Typography>;
}
const currentQuestion = currentModule?.questions[currentQuestionIndex];
const isMCQuestion = currentQuestion?.question_type === 'MC';
const selectedValue = String(selectedAnswers[currentQuestion.id]);
const currentQuestionStatistics = currentQuestion?.statistics || [];
// console.log(currentQuestionStatistics,'currentQuestionStatistics')
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };


    return (
        <>
           <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
    <MuiAlert onClose={handleCloseSnackbar} severity="info" sx={{ width: '100%' }}>
      Transitioning to the next module...
    </MuiAlert>
  </Snackbar>
          

      <div className="flex-container">
        
        <Toolbar>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <FormControl size="small" sx={{ zIndex: 9999, width: '100%' }}>
      <Breadcrumbs aria-label="breadcrumb">
        <MuiLink
            underline="hover"
            color="inherit"
            onClick={() => navigate('/manage-exams')}
            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}  // Add this line
        >
<HomeOutlinedIcon/>       </MuiLink>
       
    </Breadcrumbs>
        <Tabs
          value={currentModuleIndex}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
        >
          {currentExam.modules.map((module, index) => (
            <Tab key={module.id} label={module.name} sx={{
              '&.Mui-disabled': { opacity: 0.5, color: 'grey.500' },
              '&:focus': {
                  outline: 'none',
                },
          }}/>
          ))}
        </Tabs>
      </FormControl>
    </Box>


    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        {(
            <Button
                onClick={toggleCalculatorVisibility}
                color="primary"
                startIcon={<CalculateOutlinedIcon />}
            >
            </Button>
        )}
    </Box>
</Toolbar>



<div className="page-wrapper" id="sandbox">
  
      <div className="sidebar-wrapper middle" data-simplebar>
        {/* <div className="resize left"><img className="resizer-img" src="https://digital-sat.aoneinstitutesat.com/icons/resize.png" alt="resize layout" /></div> */}
        <div className="sidebar-content"  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(processHtmlContent(currentQuestion.text)) }}  />
      </div>


      <div className="page-content-wrapper middle" data-simplebar>
        
        {/* <div className="resize right"><img className="resizer-img right" src="https://digital-sat.aoneinstitutesat.com/icons/resize.png" alt="resize layout" /></div> */}
        <MultipleChoiceQuestion
                        question={DOMPurify.sanitize(currentQuestion.description)}
                        options={Object.entries(currentQuestion.choices || {}).map(([key, value]) => value)}
                        selectedValue={selectedValue}
                        // onValueChange={isMCQuestion ? (event) => handleAnswerSelect(currentQuestion.id, event, true) : handleInputChange}
                        questionNumber={currentQuestionIndex + 1}
                        isMCQuestion={isMCQuestion}
                        // onBlur={handleInputBlur}
                        markedForReview={markedForReview[currentQuestion.id]}
                        onMarkForReview={() => toggleMarkForReview(currentQuestion.id)}
                        crossedOut={crossedOut[currentQuestion.id] }
                        toggleCrossedOut={(index) => toggleCrossedOut(currentQuestion.id, index)} 
                        toggleTrueCrossedOut={(index) => toggleTrueCrossedOut(currentQuestion.id, index)} 
                        crossIcon={crossedIcon}
                        toggleCrossIcon={() => setCrossedIcon(!crossedIcon)}
                        statistics={currentQuestionStatistics}

                        />

            {renderInputError()}
      </div>
    </div>

      
    
        <CalculatorComponent
             isVisible={calculatorVisible}
     toggleVisibility={() => setCalculatorVisible(!calculatorVisible)}
     position={calculatorPosition}
     onPositionChange={setCalculatorPosition}
     isExpanded={isCalculatorExpanded}
     setIsExpanded={setIsCalculatorExpanded}
     myCalculatorRef={myCalculatorRef}
     toggleCalculator={toggleCalculator}
     isCalculator={true}
   />
        <CalculatorComponent
             isVisible={referenceVisible}
     toggleVisibility={() => setReferenceVisible(!referenceVisible)}
     position={referencePosition}
     
     onPositionChange={setReferencePosition}
    //  isExpanded={isCalculatorExpanded}
    //  setIsExpanded={setIsCalculatorExpanded}
     myCalculatorRef={myCalculatorRef}
     toggleCalculator={toggleReference}
     isCalculator={false}
   />
    


    
    <Footer
        user={user}
        isMinimapVisible={isMinimapVisible}
        minimapRef={minimapRef}
        currentExam={currentModule}
        currentQuestionIndex={currentQuestionIndex}
        isQuestionAnswered={isQuestionAnswered}
        toggleMinimap={toggleMinimap}
        handleSelectQuestion={handleSelectQuestion}
        openReviewPage={openReviewPage}
        handlePreviousQuestion={handlePreviousQuestion}
        handleNextQuestion={handleNextQuestion}
        showReviewPage={showReviewPage}
        setMiniMapFalse={setMiniMapFalse}
        isMarkedReview={isMarkedReview}
      />

</div>
        </>
    );
};

export default Testpagepreview;
