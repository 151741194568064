import { Alert, Snackbar, Typography, Tooltip, InputAdornment, Stack, Container, useTheme, IconButton, Box, Button, TextField } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SearchIcon from '@mui/icons-material/Search';

import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import CoPresentOutlinedIcon from '@mui/icons-material/CoPresentOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import Header from "../../components/Header";
import { fetchUsers, reset_password } from "../../actions/auth";
import FormDialog from "../form";
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingOverlay from "../../components/LoadingOverlay";

const Team = () => {
  const [users, setUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarContent, setSnackbarContent] = useState({ severity: "info", message: "" });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user);
  const { group_names } = user || {};
  const role = group_names?.[0] || '';

  useEffect(() => {
    const loadUsers = async () => {
      setIsLoading(true);
      try {
        const data = await fetchUsers();
        setUsers(data);
      } catch (error) {
        handleUserActionFeedback(false, "Error fetching users.");
      } finally {
        setIsLoading(false);
      }
    };
    loadUsers();
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarOpen(false);
  };

  const handleSearchChange = useCallback((event) => {
    setSearchText(event.target.value);
  }, []);

  const handleRowClick = useCallback((params) => {
    navigate(`/students/${params.id}/exams`);
  }, [navigate]);

  const handleUserActionFeedback = (success, message) => {
    setSnackbarContent({ severity: success ? "success" : "error", message });
    setSnackbarOpen(true);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const safeUsers = Array.isArray(users) ? users : [];

  const filteredUsers = safeUsers
    .filter((user) => (
      user.first_name.toLowerCase().includes(searchText.toLowerCase()) ||
      user.last_name.toLowerCase().includes(searchText.toLowerCase()) ||
      user.email.toLowerCase().includes(searchText.toLowerCase()) ||
      // user.phone_number?.toLowerCase().includes(searchText.toLowerCase()) ||
      user.group_names[0].toLowerCase().includes(searchText.toLowerCase())
    ))
    .sort((a, b) => {
      const roleA = a.group_names[0].toUpperCase();
      const roleB = b.group_names[0].toUpperCase();

      if (roleA === 'STUDENT' && roleB !== 'STUDENT') return -1;
      if (roleB === 'STUDENT' && roleA !== 'STUDENT') return 1;
      return a.last_name.localeCompare(b.last_name);
    });

  const totalMembers = filteredUsers.length;
  const totalStudents = filteredUsers.filter(user => user.group_names[0].toLowerCase() === 'student').length;

  const handleResetPassword = useCallback(() => {
    setIsLoading(true);
    const emailsToReset = users.filter(user => selectedUsers.includes(user.id)).map(user => user.email);

    let completedRequests = 0;
    let errorOccurred = false;
    const totalRequests = emailsToReset.length;

    if (totalRequests === 0) {
      setIsLoading(false);
      handleUserActionFeedback(false, "No users selected for password reset.");
      return;
    }

    emailsToReset.forEach(email => {
      dispatch(reset_password(email, () => {
        completedRequests++;
        if (completedRequests === totalRequests && !errorOccurred) {
          handleUserActionFeedback(true, "Password reset successful for selected users.");
          setSelectedUsers([]);
        }
        setIsLoading(false);
      }, () => {
        errorOccurred = true;
        if (completedRequests === totalRequests) {
          handleUserActionFeedback(false, "An error occurred. Not all passwords may have been reset.");
          setIsLoading(false);
        }
      }));
    });
  }, [selectedUsers, dispatch, users]);

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "first_name", headerName: "First Name", flex: 1 },
    { field: "last_name", headerName: "Last Name", flex: 1 },
    // { field: "phone_number", headerName: "Phone Number", flex: 1 }, // New phone number column

    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "groups",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { group_names } }) => {
        const role = group_names[0].toUpperCase();
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              role === 'STUDENT' ? colors.greenAccent[600] : colors.grey[500]
            }
            borderRadius="4px"
          >
            {role === 'ADMIN' && <AdminPanelSettingsOutlinedIcon />}
            {role === 'TEACHER' && <CoPresentOutlinedIcon />}
            {role === 'STUDENT' && <SchoolOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>{role}</Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Container sx={{ margin: '20px auto', maxWidth: 'lg' }}>
      <Header title="MANAGE TEAM" subtitle={`Total Members: ${totalMembers}`} subtitle2={`- Students: ${totalStudents}`} page="manageTeam" />

      <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
        <Button variant="contained" color="primary" startIcon={<AddCircleOutlineIcon />} onClick={handleOpenDialog}>
          Add User
        </Button>
        <Button variant="outlined" color="primary" startIcon={!isLoading && <RestartAltIcon />} onClick={handleResetPassword} disabled={!selectedUsers.length || isLoading}>
          {isLoading ? <LoadingOverlay /> : "Reset Password"}
        </Button>
      </Stack>

      <TextField
        fullWidth
        label="Search by Name, Email"
        variant="outlined"
        value={searchText}
        onChange={handleSearchChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Box sx={{ height: '75vh' }}>
        <DataGrid
          checkboxSelection
          rows={filteredUsers}
          columns={columns}
          onRowSelectionModelChange={(newSelection) => setSelectedUsers(newSelection)}
          selectionModel={selectedUsers}
          onRowClick={handleRowClick}
          disableSelectionOnClick
        />
      </Box>

      <FormDialog open={openDialog} handleClose={handleCloseDialog} onActionFeedback={handleUserActionFeedback} user={role} />
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert severity={snackbarContent.severity}>{snackbarContent.message}</Alert>
      </Snackbar>
    </Container>
  );
};

export default Team;
