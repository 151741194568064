import React, { useState, useEffect, useMemo,useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExams,fetchUsers } from '../../actions/auth';
import { Container, Grid, Typography, TextField, Tab, Tabs, Box,IconButton } from '@mui/material';
import ManageMyExams from '../../components/ManageExam';
import CreateExamDialog from '../../components/CreateExamDialog';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import InputAdornment from '@mui/material/InputAdornment';
import Header from '../../components/Header';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'; // Import the icon
import LoadingOverlay from '../../components/LoadingOverlay';
const TabPanel = ({ children, value, index, ...other }) => (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
        )}
    </div>
);

const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
});


const ManageExamList = () => {
  const dispatch = useDispatch();
  const allExams = useSelector((state) => state.scheduledExams.allexams);
  const [searchTerm, setSearchTerm] = useState('');
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [users, setUsers] = useState([]);
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let isActive = true;

    const fetchData = async () => {
      try {
        await dispatch(fetchExams());
        const usersData = await fetchUsers();
        if (isActive) {
          setUsers(usersData);
          setLoading(false);
        }
      } catch (error) {
        console.error('Failed to fetch data:', error);
        if (isActive) setLoading(false);
      }
    };

    fetchData();

    return () => {
      isActive = false;
    };
  }, [dispatch]);

  const handleSearchChange = useCallback((event) => {
    setSearchTerm(event.target.value.toLowerCase());
  }, []);

  const handleTabChange = useCallback((event, newValue) => {
    setTabValue(newValue);
  }, []);

  const sortedExams = useMemo(() => {
    const filtered = allExams
      .filter((exam) => exam.name.toLowerCase().includes(searchTerm))
      .sort((a, b) => {
        // First, prioritize published exams, then sort by creation date (newest first)
        if (a.is_published === b.is_published) {
          return new Date(b.created_at) - new Date(a.created_at); // Newest first
        }
        return a.is_published ? -1 : 1; // Published exams first
      });
    return filtered;
  }, [allExams, searchTerm]);


  const examTypes = useMemo(() => [...new Set(sortedExams.map((exam) => exam.exam_type))], [sortedExams]);

  const countUnpublish = useMemo(() => sortedExams.filter((exam) => !exam.is_published).length, [sortedExams]);
  const countPublish = useMemo(() => sortedExams.filter((exam) => exam.is_published).length, [sortedExams]);

  if (loading) {
    return <LoadingOverlay />;
  }

  return (
    <Container sx={{ marginTop: '20px' }}>
      <Header
        title="Manage Exams"
        subtitle={`Total Exams Created: ${countUnpublish + countPublish}`}
        subtitle2={`- Published: ${countPublish}`}
        page="manageExams"
      />
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <TextField
          label="Search Exams"
          variant="outlined"
          onChange={handleSearchChange}
          sx={{ flexGrow: 1, marginRight: 2 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <IconButton color="primary" onClick={() => setOpenDialog(true)} style={{ alignSelf: 'flex-end' }}>
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>

      <Tabs value={tabValue} onChange={handleTabChange} aria-label="Exam Type Tabs">
        {examTypes.map((type, index) => (
          <Tab label={`${type} Exams`} {...a11yProps(index)} key={type} sx={{ '&:focus': { outline: 'none' } }} />
        ))}
      </Tabs>
      {examTypes.map((type, index) => (
        <TabPanel value={tabValue} index={index} key={type}>
          <Grid container spacing={3}>
            {sortedExams
              .filter((exam) => exam.exam_type === type)
              .map((exam) => (
                <ManageMyExams key={exam.id} exam={exam} users={users} />
              ))}
          </Grid>
        </TabPanel>
      ))}

      <CreateExamDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </Container>
  );
};

export default ManageExamList;
