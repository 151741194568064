// APIError.js
export class APIError extends Error {
    constructor(response) {
      // Use the error message from the standardized response structure
      super(response?.data?.error?.message || 'Unknown error');
      this.name = 'APIError';
      this.status = response?.status;
      this.code = response?.data?.error?.code;
      this.details = response?.data?.error?.details;
      this.success = response?.data?.success;
    }
  
    static fromResponse(error) {
      if (error.response) {
        return new APIError(error.response);
      }
      // Handle network or other errors
      return new APIError({
        status: 0,
        data: {
          success: false,
          error: {
            message: error.message || 'Network error occurred',
            code: 'NETWORK_ERROR'
          }
        }
      });
    }
  }