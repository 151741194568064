import React, { useState, useEffect } from 'react';
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import DOMPurify from 'dompurify';
// import scoreChart from './scorechart.jpg';

import {
    Paper,Tabs, Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Typography,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Box,
    Breadcrumbs,Divider,
    Link as MuiLink,
    Container,TextField,Cahip,useTheme
} from '@mui/material';
// import { Rating } from '@mui/lab';

import { fetchExamAnswers } from '../actions/auth';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import PetsOutlinedIcon from '@mui/icons-material/PetsOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import LoadingOverlay from './LoadingOverlay';
const renderChoicesOrInput = (questionType, studentAnswer, choices, correctChoice, choiceToLetter) => {
  if (questionType === 'NI') {
      return (
          <TextField
              label="Your Numerical Answer"
              value={studentAnswer !== null ? studentAnswer : 'No Answer'}
              InputProps={{
                  readOnly: true,
              }}
              variant="outlined"
              fullWidth
              margin="normal"
          />
      );
  } else {
      return (
          <Grid container spacing={2} alignItems="stretch">
              {choices.map((choice, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                      <Paper
                          elevation={3}
                          sx={{
                              padding: '15px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              height: '100%', // ensures equal height for all choices
                              backgroundColor: studentAnswer === index ? '#e0f2f1' : '#fafafa',
                              border: index === correctChoice ? '2px solid #4caf50' : '1px solid #ddd',
                              '&:hover': {
                                  backgroundColor: '#f0f0f0',
                              },
                          }}
                      >
                          <Typography
                              variant="subtitle1"
                              sx={{ fontWeight: 'bold', color: '#333', marginBottom: '8px' }}
                          >
                              {choiceToLetter(index)}
                          </Typography>
                          <div
                              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(choice) }}
                              sx={{ fontSize: '0.9rem' }}
                          />
                      </Paper>
                  </Grid>
              ))}
          </Grid>
      );
  }
};


const ExamDetails = () => {
    const { examId } = useParams();
    const [examData, setExamData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [currentQuestion, setCurrentQuestion] = useState(null);
    const [currentModuleName, setCurrentModuleName] = useState('');
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    // console.log('location',location)
    const [scheduleType, setScheduleType] = useState('');
    const choiceToLetter = (index) => ['A', 'B', 'C', 'D'][index];
    const [tabValue, setTabValue] = useState(0);
    const [filteredModules, setFilteredModules] = useState([]);
    const theme = useTheme();
    // console.log('exam data',examData);
    const formatDate = (isoString) => {
        const date = new Date(isoString);
        return new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric', year: 'numeric' }).format(date);
    };
    const countStyles = {
        fontSize: '2rem', // Larger font size
        fontWeight: 'bold', // Bold font
        color: 'black', 
        lineHeight: 1, 
    };



    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
      };
      const renderTableRows = (module, moduleIndex) => {
        // Filter questions if needed based on additional criteria
        return module.questions.map((question, index) => (
          <TableRow key={question.id} style={{ backgroundColor: index % 2 ? '#fff' : '#f9f9f9' }}>
            <TableCell>{index + 1}</TableCell>
            <TableCell>{module.module_name}</TableCell>
            <TableCell>
              {question.question_type === 'NI' ? question.correct_numerical_answer : ['A', 'B', 'C', 'D'][question.correct_choice]}
            </TableCell>
            <TableCell>{renderStudentAnswer(question, question.student_answer)}</TableCell>
            <TableCell>
            <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleReview(module.id, question.id)}
                    >                Review
              </Button>
            </TableCell>
          </TableRow>
        ));
      };

      useEffect(() => {
        const handleImageClick = (event) => {
            if (event.target.classList.contains('clickable-image')) {
                setSelectedImage(event.target.src);
                setImageModalOpen(true);
            }
        };
    
        document.addEventListener('click', handleImageClick);
        return () => {
            document.removeEventListener('click', handleImageClick);
        };
    }, []);
    
    useEffect(() => {
        const fetchExamData = async () => {
            setLoading(true);
            try {
                const response = await fetchExamAnswers(examId);
                setExamData(response); 
                setScheduleType(response.schedule_type);
            } catch (error) {
            } finally {
                setLoading(false);
            }
        };

        fetchExamData();
    }, [examId]);


    useEffect(() => {
        if (examData) {
            const filteredModules = getFilteredModules();
            setFilteredModules(filteredModules);
        }
    }, [examData, tabValue]);

    const findQuestion = (moduleId, questionId) => {
        return examData.modules_data.flatMap((module) =>
            module.id === moduleId
                ? module.questions.map((q, index) => ({ ...q, moduleName: module.module_name, index }))
                : []
        ).find((q) => q.id === questionId);
    };

    


    const handleReview = (moduleId, questionId) => {
        const foundQuestion = findQuestion(moduleId, questionId);
        if (foundQuestion) {
            setCurrentQuestion(foundQuestion);
            setCurrentModuleName(foundQuestion.moduleName);
            setCurrentQuestionIndex(foundQuestion.index);
            setOpenDialog(true);
        }
    };
  // Define your styles using the sx prop syntax
  const dialogContentStyles = {
    '.MuiDialogContent-root': {
      overflowX: 'hidden', 
      overflowY: 'auto', 
      '&:first-of-type': {
        paddingTop: 0,     
      },
    },
    'img, video, iframe': {
      maxWidth: '100%',
      maxHeight: '20vh',
      width: 'auto',  
      height: 'auto',  
      display: 'block', 
      margin: '0 auto', 
    },
  };

  


  const renderScoreSummary = () => {
      if (!examData || !examData.english_score || !examData.math_score) return null;
      let totalScore= "";
      if (examData.english_score =="N/A" || examData.math_score =="N/A") {
        totalScore ="N/A";
      } else {
        totalScore = examData.english_score + examData.math_score;
      }
  
      return (
          <>
             <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    sx={{ color: 'text.secondary' }}
  >
    <Typography variant="h5" gutterBottom align="center">
      SAT Score Summary
    </Typography>
    <Typography variant="body2" align="center" sx={{ mb: 1 }}>
      Your score calculation depends on when you took the exam:
    </Typography>
    <Box component="ul" sx={{ listStyleType: 'none', paddingLeft: 0 }}>
      <Typography component="li" variant="body2" align="center" sx={{ mb: 0.5 }}>
        If you took the exam <strong>before 3 PM, August 14, 2024</strong>, click
        <MuiLink
          href={`${process.env.PUBLIC_URL}/static/before-aug14.jpg`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ ml: 0.5, mr: 0.5 }}
        >
          here
        </MuiLink>
        to see how your score was calculated.
      </Typography>
      <Typography component="li" variant="body2" align="center">
        If you took the exam <strong>after 3 PM, August 14, 2024</strong>, click
        <MuiLink
          href={`${process.env.PUBLIC_URL}/static/after-aug14.jpg`}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ ml: 0.5, mr: 0.5 }}
        >
          here
        </MuiLink>
        to see how your score was calculated.
      </Typography>
    </Box>
  </Box>
              <Divider />
              <Grid container spacing={2} justifyContent="center">
                  <Grid item xs={4}>
                      <Box sx={{ marginTop: '16px', textAlign: 'center' }}>
                          <Typography variant="h6" gutterBottom>
                              Total Score
                   
                          </Typography>
                          <Typography variant="h3" color="#2196f3" gutterBottom>
                              {totalScore}
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom>
                              In a range of 400 to 1600
                          </Typography>
                      </Box>
                  </Grid>
                  <Grid item xs={4}>
                      <Box sx={{ marginTop: '16px', textAlign: 'center' }}>
                          <Typography variant="h6" gutterBottom>
                              Reading and Writing Score
                          </Typography>
                          <Typography variant="h3" color="primary" gutterBottom>
                              {examData.english_score}
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom>
                              In a range of 200 to 800
                          </Typography>
                      </Box>
                  </Grid>
                  <Grid item xs={4}>
                      <Box sx={{ marginTop: '16px', textAlign: 'center' }}>
                          <Typography variant="h6" gutterBottom>
                              Math Score
                          </Typography>
                          <Typography variant="h3" color="primary" gutterBottom>
                              {examData.math_score}
                          </Typography>
                          <Typography variant="subtitle1" gutterBottom>
                              In a range of 200 to 800
                          </Typography>
                      </Box>
                  </Grid>
              </Grid>
          </>
      );
  };
  

  
    const handleNavigation = (direction) => {
        if (!examData || !currentQuestion) return;

        const currentModule = examData.modules_data.find((module) => module.module_name === currentModuleName);
        if (!currentModule) return;

        let newIndex = currentQuestionIndex + (direction === 'next' ? 1 : -1);
        if (newIndex >= currentModule.questions.length) newIndex = 0;
        if (newIndex < 0) newIndex = currentModule.questions.length - 1;

        const newQuestion = currentModule.questions[newIndex];
        handleReview(currentModule.id, newQuestion.id);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setCurrentQuestion(null);
        setCurrentModuleName('');
        setCurrentQuestionIndex(0);
    };
    const [imageModalOpen, setImageModalOpen] = useState(false);
const [selectedImage, setSelectedImage] = useState(null);

const handleImageClick = (src) => {
    setSelectedImage(src);
    setImageModalOpen(true);
};

const handleImageModalClose = () => {
    setImageModalOpen(false);
    setSelectedImage(null);
};
    const renderDialog = () => {
        if (!currentQuestion) return null;
    
        const {
            text,
            description,
            choices,
            correct_numerical_answer,
            correct_choice,
            student_answer,
            is_correct,
            question_type,
        } = currentQuestion;
        const correctAnswerText = question_type === 'NI' ? correct_numerical_answer : choiceToLetter(correct_choice);
        const sanitizedText = DOMPurify.sanitize(text);
        const sanitizedDescription = DOMPurify.sanitize(description);
    
        // Add event listener to images inside `dangerouslySetInnerHTML`
        const enhanceImageClick = (htmlString) => {
            return htmlString.replace(
                /<img /g,
                `<img style="cursor: pointer;" class="clickable-image" `
            );
        };
        
        const studentAnswerDisplay = question_type === 'NI'
        ? (student_answer !== null ? student_answer : 'No Answer')
        : (student_answer !== null ? choiceToLetter(student_answer) : 'No Answer');
    
        const answerText = is_correct
            ? `You selected the correct answer. The correct answer is ${correctAnswerText}.`
            : `You selected answer ${studentAnswerDisplay}. The correct answer is ${correctAnswerText}.`;
        return (
            <Dialog
                open={openDialog}
                onClose={handleClose}
                fullScreen
                sx={{
                    '& .MuiDialog-paper': {
                        width: '100%',
                        height: '100%',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        padding: 3,
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        fontSize: '1.8rem',
                        fontWeight: 'bold',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {currentModuleName} - Question {currentQuestionIndex + 1}
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
    
                <DialogContent sx={{ fontSize: '1.2rem', overflowY: 'auto' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <div
                                dangerouslySetInnerHTML={{ __html: enhanceImageClick(sanitizedText) }}
                                style={{ fontSize: '1.5rem' }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <div
                                dangerouslySetInnerHTML={{ __html: enhanceImageClick(sanitizedDescription) }}
                                style={{ fontSize: '1.3rem' }}
                            />
                            {renderChoicesOrInput(question_type, student_answer, choices, correct_choice, choiceToLetter)}
                            <Typography
                                sx={{
                                    backgroundColor: is_correct ? 'lightgreen' : 'lightcoral',
                                    padding: '8px',
                                    marginTop: '8px',
                                    fontSize: '1.3rem',
                                }}
                            >
                                {answerText}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
    
                <DialogActions>
                    <Button
                        onClick={() => handleNavigation('previous')}
                        color="primary"
                        disabled={currentQuestionIndex === 0}
                    >
                        Previous
                    </Button>
                    <Button
                        onClick={() => handleNavigation('next')}
                        color="primary"
                        disabled={
                            currentQuestionIndex ===
                            examData.modules_data.find((module) => module.module_name === currentModuleName).questions.length - 1
                        }
                    >
                        Next
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };
    const renderStudentAnswer = (question, studentAnswerIndex) => {
        const { is_correct, question_type } = question;
        const answerText =
        studentAnswerIndex !== null 
        ? (question_type === 'NI' ? `${studentAnswerIndex}` : choiceToLetter(studentAnswerIndex))
        : 'No Answer';        const answerColor = is_correct ? 'green' : 'red';
        return (
            <Typography style={{ color: answerColor, fontWeight: 'bold' }}>
                {answerText}: {is_correct ? 'Correct' : 'Incorrect'}
            </Typography>
        );
    };

    if (loading) {
        return (
    <LoadingOverlay/>
        );
    }

    if (!examData) {
        return <Typography>No exam data available</Typography>;
    }
    const getFilteredModules = () => {
        if (!examData || !examData.modules_data) return [];
    
        // Depending on the tab, filter the modules
        switch (tabValue) {
          case 1:
            return examData.modules_data.slice(0, 2);
          case 2:
            return examData.modules_data.slice(-2);
          default:
            return examData.modules_data;
        }
      };
      const navigateHome = () => {
        // console.log('go home clicked')

        if ((location.state && location.state.from)) {
            // console.log(location.state.hist)
            navigate('/schedules');
        }
        else {
            navigate('/my-exams')

        }
    };

    const navigateBack = () => {
        if (location.state?.from) {
            navigate(location.state.from)
        }
    };


    const hasEnglish = scheduleType==='Full' || scheduleType==='English';
    const hasMath = scheduleType==='Full' || scheduleType==='Math'
    // const scoreCalculationPdfUrl = {br};


    return (
        <Container >

<Box sx={{ width: '100%', margin: '20px 0' }}>
    <Breadcrumbs aria-label="breadcrumb">
        <MuiLink
            underline="hover"
            color="inherit"
            onClick={navigateHome}
            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
        >
            Home
        </MuiLink>
        {location.state && location.state.from && (
            <MuiLink
                underline="hover"
                color="inherit"
                onClick={navigateBack}
                sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}  // And optionally add this for consistency
            >
                Student Exams
            </MuiLink>
        )}
                    <Typography color="text.primary">{examData ? `${examData.exam_name} - ${formatDate(examData.started_time)}` : ''}</Typography>
        <Typography color="text.primary">My Practice</Typography>
    </Breadcrumbs>
</Box>

<Paper elevation={3} sx={{ mb: 4, p: 3, borderRadius: 2 }}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', mb: 2 }}>
                Exam Details: {examData.exam_name} - {examData.schedule_type}
            </Typography>
            <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <SchoolOutlinedIcon sx={{ mr: 1, color: theme.palette.primary.main }} />
                Exam Taker: {examData.student_name}
            </Typography>
            
            {renderScoreSummary()}

            
        </Paper>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 3, mb: 2 }}>
            <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                aria-label="exam details tabs"
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"

            >
                <Tab icon={<PetsOutlinedIcon />} iconPosition="start" label="All Questions" sx={{
                    '&.Mui-disabled': { opacity: 0.5, color: 'grey.500' },
                    '&:focus': {
                        outline: 'none',
                      },
                }} />
                <Tab icon={<AutoStoriesOutlinedIcon />} iconPosition="start" label="Reading and Writing" disabled={!hasEnglish} sx={{
                    '&.Mui-disabled': { opacity: 0.5, color: 'grey.500' },
                    '&:focus': {
                        outline: 'none',
                      }, 
                }} />
                <Tab icon={<CalculateOutlinedIcon />} iconPosition="start" label="Math" disabled={!hasMath} sx={{
                    '&.Mui-disabled': { opacity: 0.5, color: 'grey.500' }, 
                    '&:focus': {
                        outline: 'none',
                      },
                }} />
            </Tabs>
        </Box>


            <Grid container spacing={2} justifyContent="center">
    <Grid item xs={12} md={12} lg={12}>
    {filteredModules.length > 0 ? (
        <TableContainer component={Paper} sx={{ marginBottom: '1px' }}>
            <Table aria-label="exam details table">
                <TableHead style={{ backgroundColor: '#f5f5f5' }}>
                    <TableRow>
                        <TableCell style={{ width: '20%' }}>Question</TableCell>
                        <TableCell style={{ width: '20%' }}>Section</TableCell>
                        <TableCell style={{ width: '20%' }}>Correct Answer</TableCell>
                        <TableCell style={{ width: '20%' }}>Your Answer</TableCell>
                        <TableCell style={{ width: '20%' }}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
  {filteredModules.map((module, moduleIndex) => (
    <React.Fragment key={module.id}>
      {moduleIndex != 0 ? (
        <TableRow style={{ borderTop: '1.5px solid #000' }}></TableRow>
      ) : null}
      {renderTableRows(module, moduleIndex)}
    </React.Fragment>
  ))}
</TableBody>

            </Table>
        </TableContainer>
    ) : (
        <Typography>No exam data available for the selected tab.</Typography>
    )}
    </Grid>
</Grid>
            {renderDialog()}
            <Dialog open={imageModalOpen} onClose={handleImageModalClose} fullScreen>
    <DialogTitle>
        <IconButton onClick={handleImageModalClose}>
            <CloseIcon />
        </IconButton>
    </DialogTitle>
    <DialogContent sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={selectedImage} alt="Enlarged" style={{ maxWidth: '100%', maxHeight: '100%' }} />
    </DialogContent>
</Dialog>


        </Container>
    );
};

export default ExamDetails;