


import React, { useEffect, useState } from 'react';
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import { fetchOneStudent } from '../../actions/auth';
import {
    Container, Paper, TableContainer, Table, Box, Link as MuiLink, Breadcrumbs,
    TableHead, TableRow, TableCell, TableBody, Button, LinearProgress, Typography, Chip,styled,useTheme,IconButton,Collapse,
    useScrollTrigger, Fab, Zoom,CircularProgress,Card,CardContent,Tooltip,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import OldHeader from '../../components/oldHeader';
import LoadingOverlay from '../../components/LoadingOverlay';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'; // Import this icon
import ExamChart from '../../components/ExamChart';


  
  function ModuleScoreBar({ score, correct, total }) {
    const theme = useTheme();
        const location = useLocation();

    let percentage = (correct / total) * 100;
    if (isNaN(percentage)) {
      // Early return for NaN case
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Tooltip title="Not Available" placement="top">
            <InfoOutlinedIcon color="action" />
          </Tooltip>
          <Typography variant="caption" sx={{ ml: 1, color: theme.palette.text.disabled }}>
            N/A
          </Typography>
        </Box>
      );
    }
  
    // Determine the color based on the percentage
    const getColor = () => {
      if (percentage >= 75) {
        return theme.palette.success.main; // Green for high scores
      } else if (percentage >= 50) {
        return theme.palette.warning.main; // Yellow for medium scores
      } else {
        return theme.palette.error.main; // Red for low scores
      }
    };
  
    return (
      <Box sx={{ py: 1, px: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', backgroundColor: theme.palette.background.paper, borderRadius: '4px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
            Score: {score}
          </Typography>
          <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
            {`${correct}/${total} Correct`}
          </Typography>
        </Box>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" value={percentage} size={40} thickness={4} style={{ color: getColor() }} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="text.primary">
              {`${Math.round(percentage)}%`}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }
  


const OneStudent = () => {
    const [studentExams, setStudentExams] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [studentName, setStudentName] = useState("");
    const { studentId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [expanded, setExpanded] = useState({}); // 保存每行是否展开

    const toggleExpand = (examId) => {
      setExpanded((prev) => ({
        ...prev,
        [examId]: !prev[examId], // Only update the state for the clicked row
      }));
    };
    
    useEffect(() => {
        const fetchStudentExams = async () => {
            setIsLoading(true);
            const data = await fetchOneStudent(studentId);
            console.log('data',data)
            if (data && data.length > 0) {
                setStudentName(data[0].student_info.name);
                const now = new Date();
                const sortedData = data.sort((a, b) => {
                    // Move 'Missed Start' status to the bottom
                    const endTimeA = new Date(a.exam_schedule.end_time);
                    const endTimeB = new Date(b.exam_schedule.end_time);
                    const statusA = a.status === 'B' && now > endTimeA ? 'Missed Start' : a.status;
                    const statusB = b.status === 'B' && now > endTimeB ? 'Missed Start' : b.status;
    
                    if (statusA === 'Missed Start' && statusB !== 'Missed Start') return 1;
                    if (statusB === 'Missed Start' && statusA !== 'Missed Start') return -1;
    
                    // Then, sort by start time from most recent to oldest
                    const startTimeA = new Date(a.exam_schedule.start_time);
                    const startTimeB = new Date(b.exam_schedule.start_time);
                    return startTimeB - startTimeA; // For descending order
                }).filter(exam => {
                    const endTime = new Date(exam.exam_schedule.end_time);
                    return !(exam.status === 'B' && now > endTime); // Exclude 'Missed Start'
                });                setStudentExams(sortedData);
            }
            setIsLoading(false);
        };
        fetchStudentExams();
    }, [studentId]);

    // Function implementations for formatDateTime, getStatusChip, calculateProgress, and getProgressColor remain unchanged
    const formatDateTime = (date) => {
        // console.log('data',date)
        return date.toLocaleString('en-US', {
          month: 'short', // Abbreviated month name
          day: 'numeric', // Day of the month
          year: 'numeric', // Four digit year
          hour: 'numeric', // Hour (1-12)
          minute: 'numeric', // Minute (0-59)
          hour12: true // 12-hour format with AM/PM
        });
      };
      const getStatusChip = (status, startTime, endTime) => {
        const now = new Date();
        const start = new Date(startTime);
        const end = new Date(endTime);
    
        let label = "Unknown";
        let color = "default";
    
        if (status === 'B') {
            if (now > end) {
                label = "Missed Start";
                color = "error";
            } else {
                label = "Scheduled";
                color = "primary";
            }
        } else if (status === 'P') {
            label = "In Progress";
            color = "secondary";
        } else if (status === 'C') {
            label = "Completed";
            color = "success";
        }
    
        return <Chip
        label={label}
        color={color}
        size='small'
        sx={{
            fontSize: '0.75rem', // Adjust font size for smaller appearance
            // height: '24px', // Adjust height for a more compact look
            // margin: '2px', // Optionally adjust margins
        }}
    />;
    };

      



    const navigateHome = () => {
      // console.log('go home clicked')

      if ((location.state && location.state.schedule)) {
          navigate('/schedules');
      } else if (location.state && location.state.from) {
        // console.log('')
      }else {
          navigate('/team')

      }
  };
  const renderBreakdownRow = (label, correct, total) => {
    const incorrect = total ? total - correct : 0;
    const percentage = total ? Math.round((correct / total) * 100) : 0;
    return (
      <TableRow key={label}>
        <TableCell>{label}</TableCell>
        <TableCell>{correct}</TableCell>
        <TableCell>{incorrect}</TableCell>
        <TableCell>{total}</TableCell>
        <TableCell>{total ? `${percentage}%` : 'N/A'}</TableCell>
      </TableRow>
    );
  };


    const handleNavigateToExamDetails = (examId) => {
        navigate(`/exam-details/${examId}`);
    };

    return (
        <Container>
           
           <Box sx={{ my: 2 }}>
    <Breadcrumbs aria-label="breadcrumb">
        <MuiLink
            underline="hover"
            color="inherit"
            onClick={() => navigateHome()}
            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}  // Add this line
        >
            Home
        </MuiLink>
        {location.state && location.state.referrer && (
            <MuiLink
                underline="hover"
                color="inherit"
                sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}  // And optionally add this for consistency
            >
                Student Exams
            </MuiLink>
        )}
                    <Typography color="text.primary">{studentName ? `${studentName}` : ''}</Typography>
        <Typography color="text.primary">Student Exam</Typography>
    </Breadcrumbs>
</Box>



<OldHeader title={`${studentName}`} subtitle="Track the exam progress and details"/>
{/* <Box sx={{ width: '100%', height: '400px', mt: 4 }}>
        {isLoading ? <CircularProgress /> : <ExamChart studentExams={studentExams} />}
      </Box> */}
            {isLoading ? <LoadingOverlay/> : studentExams.length === 0 ? (
                <Typography variant="h6" sx={{ mt: 2 }}>No exam records found for this student.</Typography>
            ) : (
                <TableContainer component={Paper} elevation={3}>
    <Table aria-label="student exams table">
        <TableHead>
            <TableRow>
                <TableCell align="center">Exam Name</TableCell>
                {/* <TableCell>Student</TableCell> */}
                <TableCell align="center">Start Time</TableCell>

                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Total Score</TableCell>

                <TableCell align="center">English Score</TableCell>
    <TableCell align="center">Math Score</TableCell>

                {/* <TableCell>End Time</TableCell> */}
                {/* <TableCell>Current Module</TableCell> */}
                {/* <TableCell>Progress</TableCell> */}
                <TableCell>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {studentExams.map((exam) => (
                              <React.Fragment key={exam.id}>
                <TableRow key={exam.id}>
                    <TableCell>{exam.exam_schedule.exam_name} - {exam.exam_schedule.schedule_type}</TableCell>
                    <TableCell>{formatDateTime(new Date(exam.exam_schedule.start_time))}</TableCell>
                    <TableCell>{getStatusChip(exam.status, exam.exam_schedule.start_time, exam.exam_schedule.end_time)}</TableCell>
                    <TableCell>
  {exam.exam_schedule.schedule_type === 'Full' ? (
    <ModuleScoreBar 
      score={exam.eng_score + exam.math_score} 
      correct={exam.eng_correct + exam.math_correct} 
      total={exam.eng_total + exam.math_total} 
    />
  ) : (
    <ModuleScoreBar 
      score={0} 
      correct={0} 
      total={0} 
    />
  )}
</TableCell>
                    <TableCell>
                    <ModuleScoreBar score={exam.eng_score} correct={exam.eng_correct} total={exam.eng_total} />
                  </TableCell>
                  <TableCell>
                    <ModuleScoreBar score={exam.math_score} correct={exam.math_correct} total={exam.math_total} />
                  </TableCell>


                    <TableCell>
                    <IconButton onClick={() => toggleExpand(exam.id)} size="small"  sx={{
         '&:focus': {
           outline: 'none',
         }       }}>
                        <ExpandMoreIcon
                          sx={{
                            transform: expanded[exam.id] ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s',
                          }}
                          
                        />
                      </IconButton>
                        <Button
                            variant="text"
                            color="primary"
                            disabled={exam.status !== 'C'}
                            onClick={() => handleNavigateToExamDetails(exam.id)}
                        >
                            Review
                        </Button>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                      <Collapse in={expanded[exam.id]} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Typography variant="subtitle1" gutterBottom component="div">
                            Detailed Breakdown
                          </Typography>
                          <Table size="small" aria-label="breakdown">
                            <TableHead>
                              <TableRow>
                                <TableCell>Section</TableCell>
                                <TableCell>Correct</TableCell>
                                <TableCell>Incorrect</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Percentage</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {/*
                                假设 exam 对象中包含以下字段：
                                - English 分项：eng1_correct, eng1_total, eng2_correct, eng2_total
                                - Math 分项：math1_correct, math1_total, math2_correct, math2_total
                              */}
                              {renderBreakdownRow(
                                'English Part 1',
                                exam.eng1_correct,
                                exam.eng_total/2
                              )}
                              {renderBreakdownRow(
                                'English Part 2',
                                exam.eng2_correct,
                                exam.eng_total/2
                              )}
                              {renderBreakdownRow(
                                'Math Part 1',
                                exam.math1_correct,
                                exam.math_total/2
                              )}
                              {renderBreakdownRow(
                                'Math Part 2',
                                exam.math2_correct,
                                exam.math_total/2
                              )}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
            ))}
        </TableBody>
    </Table>
</TableContainer>
            )}

        </Container>
    );
};

export default OneStudent;