import { useState } from 'react';
import { APIError } from './error';

export const useErrorHandler = () => {
  const [errorState, setErrorState] = useState({
    open: false,
    message: '',
    code: '',
    details: null,
    severity: 'error',
    duration: 5000
  });

  const handleError = (error) => {
    console.log('Full error object:', error);
    console.log('Error response structure:', {
      response: error?.response,
      data: error?.response?.data,
      error: error?.response?.data?.error,
      message: error?.response?.data?.error?.message,
      status: error?.response?.status
    });
    
    // 特殊处理 403 状态码
    if (error?.response?.status === 403) {
      setErrorState({
        open: true,
        message: 'This exam has already been completed',
        code: 'EXAM_ALREADY_COMPLETED',
        severity: 'info',
        duration: 3000
      });
      return;
    }

    if (error?.response?.data?.error?.message) {
      setErrorState({
        open: true,
        message: error.response.data.error.message,
        severity: 'error',
        duration: 5000
      });
    } else if (error?.response?.data?.message) {
      setErrorState({
        open: true,
        message: error.response.data.message,
        severity: 'error',
        duration: 5000
      });
    } else if (error?.message) {
      setErrorState({
        open: true,
        message: error.message,
        severity: 'error',
        duration: 5000
      });
    } else {
      setErrorState({
        open: true,
        message: 'An unexpected error occurred',
        severity: 'error',
        duration: 5000
      });
    }
  };

  const handleAPIError = (apiError) => {
    switch (apiError.code) {
      case 'EXAM_ALREADY_COMPLETED':
        setErrorState({
          open: true,
          message: apiError.message,
          code: apiError.code,
          details: apiError.details,
          severity: 'info',
          duration: 3000
        });
        break;

      case 'NO_ANSWERS':
        setErrorState({
          open: true,
          message: apiError.message,
          code: apiError.code,
          details: apiError.details,
          severity: 'warning',
          duration: 4000
        });
        break;

      default:
        setErrorState({
          open: true,
          message: apiError.message,
          code: apiError.code,
          details: apiError.details,
          severity: 'error',
          duration: 5000
        });
    }
  };

  const clearError = () => {
    setErrorState(prev => ({ ...prev, open: false }));
  };

  return { errorState, handleError, handleAPIError, clearError };
};