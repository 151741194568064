import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { liststudentExam } from '../../actions/auth';
import {
  Container,
  Paper,
  TableContainer,
  Table,
  Box,
  Link as MuiLink,
  Breadcrumbs,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  LinearProgress,
  Typography,
  Chip,
  styled,
  useTheme,
  useScrollTrigger,
  Fab,
  Zoom,
  CircularProgress,
  Card,
  CardContent,
  Tooltip,
  IconButton,
  Collapse,
} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import OldHeader from '../../components/oldHeader';
import LoadingOverlay from '../../components/LoadingOverlay';

function ScrollTop(props) {
  const { children } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger}>
      <Box onClick={handleClick} role="presentation" sx={{ position: 'fixed', bottom: 16, right: 16 }}>
        {children}
      </Box>
    </Zoom>
  );
}

function ModuleScoreBar({ score, correct, total }) {
  const theme = useTheme();
  let percentage = (correct / total) * 100;
  if (isNaN(percentage)) {
    // Early return for NaN case
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
        <Tooltip title="Not Available" placement="top">
          <InfoOutlinedIcon color="action" />
        </Tooltip>
        <Typography variant="caption" sx={{ ml: 1, color: theme.palette.text.disabled }}>
          N/A
        </Typography>
      </Box>
    );
  }

  const getColor = () => {
    if (percentage >= 75) {
      return theme.palette.success.main;
    } else if (percentage >= 50) {
      return theme.palette.warning.main;
    } else {
      return theme.palette.error.main;
    }
  };

  return (
    <Box
      sx={{
        py: 1,
        px: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        borderRadius: '4px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <Typography variant="subtitle1" component="div" sx={{ fontWeight: 'bold' }}>
          Score: {score}
        </Typography>
        <Typography variant="caption" sx={{ color: theme.palette.text.secondary }}>
          {`${correct}/${total} Correct`}
        </Typography>
      </Box>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" value={percentage} size={40} thickness={4} style={{ color: getColor() }} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.primary">
            {`${Math.round(percentage)}%`}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

const StudentExamList = () => {
  const [studentExams, setStudentExams] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [studentName, setStudentName] = useState("");
  const [expanded, setExpanded] = useState({}); // 保存每行是否展开
  const { scheduleId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const formatDateTime = (date) => {
    return new Date(date).toLocaleString('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  useEffect(() => {
    const fetchStudentExams = async () => {
      setIsLoading(true);
      const data = await liststudentExam(scheduleId);
      if (data && data.length > 0) {
        setStudentName(data[0].student_info.name);
        setStudentExams(data.sort((a, b) => b.status.localeCompare(a.status)));
      }
      setIsLoading(false);
    };
    fetchStudentExams();
  }, [scheduleId]);

  const getStatusChip = (status, startTime, endTime) => {
    const now = new Date();
    const start = new Date(startTime);
    const end = new Date(endTime);

    let label = "Unknown";
    let color = "default";

    if (status === 'B') {
      if (now > end) {
        label = "Missed Start";
        color = "error";
      } else {
        label = "Scheduled";
        color = "primary";
      }
    } else if (status === 'P') {
      label = "In Progress";
      color = "secondary";
    } else if (status === 'C') {
      label = "Completed";
      color = "success";
    }

    return (
      <Chip
        label={label}
        color={color}
        size="small"
        sx={{
          fontSize: '0.75rem',
        }}
      />
    );
  };

  const handleRowClick = (studentId) => {
    navigate(`/students/${studentId}/exams`, { state: { schedule: '' } });
  };

  const handleNavigateToExamDetails = (examId) => {
    navigate(`/exam-details/${examId}`, { state: { from: location.pathname } });
  };

  const examDetails = studentExams[0]?.exam_schedule;

  const calculateProgress = (currentModuleName, status) => {
    if (status === 'C') return 100;
    const moduleOrder = ['Reading and Writing 1', 'Reading and Writing 2', 'Math 1', 'Math 2'];
    const index = moduleOrder.indexOf(currentModuleName);
    return index >= 0 ? ((index + 1) / moduleOrder.length) * 100 : 0;
  };

  const getProgressColor = (status) => ({
    'B': 'info',
    'P': 'warning',
    'C': 'success',
  }[status] || 'grey');

  // 切换展开状态
  const toggleExpand = (examId) => {
    setExpanded((prev) => ({ ...prev, [examId]: !prev[examId] }));
  };

  // 用于显示分项明细的函数，计算错误数和百分比
  const renderBreakdownRow = (label, correct, total) => {
    const incorrect = total ? total - correct : 0;
    const percentage = total ? Math.round((correct / total) * 100) : 0;
    return (
      <TableRow key={label}>
        <TableCell>{label}</TableCell>
        <TableCell>{correct}</TableCell>
        <TableCell>{incorrect}</TableCell>
        <TableCell>{total}</TableCell>
        <TableCell>{total ? `${percentage}%` : 'N/A'}</TableCell>
      </TableRow>
    );
  };

  return (
    <Container>
      <Box sx={{ my: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <MuiLink
            underline="hover"
            color="inherit"
            onClick={() => navigate('/schedules')}
            sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
          >
            Home
          </MuiLink>
          {location.state && location.state.referrer && (
            <MuiLink
              underline="hover"
              color="inherit"
              sx={{ cursor: 'pointer', '&:hover': { textDecoration: 'underline' } }}
            >
              Student Exams
            </MuiLink>
          )}
          <Typography color="text.primary">Student Exams</Typography>
        </Breadcrumbs>
      </Box>

      <OldHeader title="My Student Exams" subtitle="Track student progress for the selected schedule" page="scheduleDetail" />

      {examDetails && (
        <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
          <Typography variant="h6">Exam Details</Typography>
          <Typography>Name: {examDetails.exam_name}</Typography>
          <Typography>Created Time: {formatDateTime(examDetails.created_at)}</Typography>
          <Typography>Start Time: {formatDateTime(examDetails.start_time)}</Typography>
          <Typography>End Time: {formatDateTime(examDetails.end_time)}</Typography>
          <Typography>Duration: {examDetails.duration} minutes</Typography>
        </Paper>
      )}

      {isLoading ? (
        <LoadingOverlay />
      ) : studentExams.length === 0 ? (
        <Typography variant="h6" sx={{ mt: 2 }}>
          No exam records found for this student.
        </Typography>
      ) : (
        <TableContainer component={Paper} elevation={3}>
          <Table aria-label="student exams table">
            <TableHead>
              <TableRow>
                <TableCell>Student</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Progress</TableCell>
                <TableCell align="center">Total Score</TableCell>
                <TableCell align="center">English Score</TableCell>
                <TableCell align="center">Math Score</TableCell>
                <TableCell align="center">Started Time</TableCell>
                <TableCell align="center">Completed Time</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {studentExams.map((exam) => (
                <React.Fragment key={exam.id}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <span key={exam.student_info.id}>
                        <MuiLink
                          component="button"
                          onClick={() => handleRowClick(exam.student_info.id)}
                          color="primary"
                        >
                          {exam.student_info.name}
                        </MuiLink>
                      </span>
                    </TableCell>
                    <TableCell>
                      {getStatusChip(exam.status, exam.exam_schedule.start_time, exam.exam_schedule.end_time)}
                    </TableCell>
                    <TableCell>
                      <LinearProgress
                        variant="determinate"
                        value={calculateProgress(exam.current_module_name, exam.status)}
                        color={getProgressColor(exam.status)}
                      />
                    </TableCell>
                    <TableCell>
                      <ModuleScoreBar
                        score={exam.eng_score + exam.math_score}
                        correct={exam.eng_correct + exam.math_correct}
                        total={exam.eng_total + exam.math_total}
                      />
                    </TableCell>
                    <TableCell>
                      <ModuleScoreBar score={exam.eng_score} correct={exam.eng_correct} total={exam.eng_total} />
                    </TableCell>
                    <TableCell>
                      <ModuleScoreBar score={exam.math_score} correct={exam.math_correct} total={exam.math_total} />
                    </TableCell>
                    <TableCell align="center">
                      {exam.exam_start_time ? formatDateTime(exam.exam_start_time) : 'N/A'}
                    </TableCell>
                    <TableCell align="center">
                      {exam.completed_exam_time ? formatDateTime(exam.completed_exam_time) : 'N/A'}
                    </TableCell>
                    <TableCell align="center">
                      {/* 展开/收起明细按钮 */}
                      <IconButton onClick={() => toggleExpand(exam.id)} size="small"  sx={{
         '&:focus': {
           outline: 'none',
         }       }}>
                        <ExpandMoreIcon
                          sx={{
                            transform: expanded[exam.id] ? 'rotate(180deg)' : 'rotate(0deg)',
                            transition: 'transform 0.3s',
                          }}
                          
                        />
                      </IconButton>
                      <Button
                        variant="text"
                        color="primary"
                        disabled={exam.status !== 'C'}
                        onClick={() => handleNavigateToExamDetails(exam.id)}
                      >
                        Review
                      </Button>
                    </TableCell>
                  </TableRow>
                  {/* 展开区域 */}
                  <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                      <Collapse in={expanded[exam.id]} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                          <Typography variant="subtitle1" gutterBottom component="div">
                            Detailed Breakdown
                          </Typography>
                          <Table size="small" aria-label="breakdown">
                            <TableHead>
                              <TableRow>
                                <TableCell>Section</TableCell>
                                <TableCell>Correct</TableCell>
                                <TableCell>Incorrect</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Percentage</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {/*
                                假设 exam 对象中包含以下字段：
                                - English 分项：eng1_correct, eng1_total, eng2_correct, eng2_total
                                - Math 分项：math1_correct, math1_total, math2_correct, math2_total
                              */}
                              {renderBreakdownRow(
                                'English Part 1',
                                exam.eng1_correct,
                                exam.eng_total/2
                              )}
                              {renderBreakdownRow(
                                'English Part 2',
                                exam.eng2_correct,
                                exam.eng_total/2
                              )}
                              {renderBreakdownRow(
                                'Math Part 1',
                                exam.math1_correct,
                                exam.math_total/2
                              )}
                              {renderBreakdownRow(
                                'Math Part 2',
                                exam.math2_correct,
                                exam.math_total/2
                              )}
                            </TableBody>
                          </Table>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <ScrollTop>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </Container>
  );
};

export default StudentExamList;
